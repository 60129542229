import { ChatIcon, HandIcon, PersonIcon } from "@100mslive/react-icons";
import { TextWithIcon } from "../Notifications/TextWithIcon";

export const ToastConfig = {
  PEER_LIST: {
    single: function (notification) {
      if (notification.data.length === 1) {
        return (
          <TextWithIcon
            Icon={PersonIcon}
          >{`${notification.data[0]?.name} entrou`}</TextWithIcon>
        );
      }
      return (
        <TextWithIcon Icon={PersonIcon}>
          {`${notification.data[notification.data.length - 1]?.name} e ${
            notification.data.length - 1
          } outros entraram`}
        </TextWithIcon>
      );
    },
    multiple: notifications => {
      return (
        <TextWithIcon Icon={PersonIcon}>
          {`${notifications[0].data.name} e ${
            notifications.length - 1
          } outros entraram`}
        </TextWithIcon>
      );
    },
  },
  PEER_JOINED: {
    single: function (notification) {
      return (
        <TextWithIcon
          Icon={PersonIcon}
        >{`${notification.data?.name} entrou`}</TextWithIcon>
      );
    },
    multiple: function (notifications) {
      return (
        <TextWithIcon Icon={PersonIcon}>
          {`${notifications[notifications.length - 1].data.name} e ${
            notifications.length - 1
          } outros entraram`}
        </TextWithIcon>
      );
    },
  },
  PEER_LEFT: {
    single: function (notification) {
      return (
        <TextWithIcon
          Icon={PersonIcon}
        >{`${notification.data?.name} saiu`}</TextWithIcon>
      );
    },
    multiple: function (notifications) {
      return (
        <TextWithIcon Icon={PersonIcon}>
          {`${notifications[notifications.length - 1].data.name} e ${
            notifications.length - 1
          } outros sairam`}
        </TextWithIcon>
      );
    },
  },
  METADATA_UPDATED: {
    single: notification => {
      return (
        <TextWithIcon
          Icon={HandIcon}
        >{`${notification.data?.name} levantou a mão`}</TextWithIcon>
      );
    },
    multiple: notifications => {
      return (
        <TextWithIcon Icon={HandIcon}>
          {`${notifications[notifications.length - 1].data?.name} e ${
            notifications.length - 1
          } outros levantaram a mão`}
        </TextWithIcon>
      );
    },
  },
  NEW_MESSAGE: {
    single: notification => {
      return (
        <TextWithIcon
          Icon={ChatIcon}
        >{`Nova mensagem de ${notification.data?.senderName}`}</TextWithIcon>
      );
    },
    multiple: notifications => {
      return (
        <TextWithIcon Icon={ChatIcon}>
          {`${notifications.length} novas mensagens`}
        </TextWithIcon>
      );
    },
  },
};
