import { ConnectivityIcon, PoorConnectivityIcon } from "@100mslive/react-icons";
import {
  HMSNotificationTypes,
  useHMSNotifications
} from "@100mslive/react-sdk";
import LogRocket from "logrocket";
import { useEffect } from "react";
import { ToastManager } from "../Toast/ToastManager";
import { TextWithIcon } from "./TextWithIcon";

const notificationTypes = [
  HMSNotificationTypes.RECONNECTED,
  HMSNotificationTypes.RECONNECTING,
];
let notificationId = null;
export const ReconnectNotifications = () => {
  const notification = useHMSNotifications(notificationTypes);
  useEffect(() => {
    if (notification?.type === HMSNotificationTypes.RECONNECTED) {
      LogRocket.track("Reconnected");
      notificationId = ToastManager.replaceToast(notificationId, {
        title: (
          <TextWithIcon Icon={ConnectivityIcon}>
            Você está conectado.
          </TextWithIcon>
        ),
        duration: 3000,
      });
    } else if (notification?.type === HMSNotificationTypes.RECONNECTING) {
      LogRocket.track("Reconnecting");
      notificationId = ToastManager.replaceToast(notificationId, {
        title: (
          <TextWithIcon Icon={PoorConnectivityIcon}>
            Você está OFFLINE! Verifique sua conexão de internet ou atualize a
            página.
          </TextWithIcon>
        ),
        duration: 10000,
      });
    }
  }, [notification]);
  return null;
};
