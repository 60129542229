import {
  Box,
  Button,
  Flex,
  HorizontalDivider,
  Text
} from "@100mslive/react-ui";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

const PostLeave = () => {
  const navigate = useNavigate();
  const { roomId } = useParams();
  return (
    <Flex justify="center" align="center" css={{ size: "100%", bg: "$mainBg" }}>
      <Box
        css={{
          position: "relative",
          overflow: "hidden",
          w: "37.5rem",
          maxWidth: "80%",
          h: "75%",
          maxHeight: "42.5rem",
          r: "$3",
          m: "0 auto",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          "@md": {
            width: "100%",
            height: "100%",
            maxWidth: "unset",
            maxHeight: "unset",
          },
        }}
      >
        <Flex
          align="center"
          direction="column"
          css={{
            position: "absolute",
            w: "100%",
            top: 0,
            left: 0,
            pt: "20%",
            textAlign: "center",
            background:
              "linear-gradient(rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%)",
          }}
        >
          <Text
            color="white"
            variant="h5"
            css={{ fontWeight: "$semiBold", mt: "$12" }}
          >
            Você saiu da chamada.
          </Text>
          <Text
            color="white"
            variant="h5"
            css={{
              mt: "$8",
              fontWeight: "$semiBold",
            }}
          >
            Tenha um ótimo dia!
          </Text>
          <HorizontalDivider
            css={{ bg: "$textPrimary", maxWidth: "50%", m: "$10 0" }}
          />
          <Flex justify="center">
            <Button
              onClick={() => {
                let previewUrl = "/preview/" + roomId;
                navigate(previewUrl);
              }}
              css={{ mx: "$4" }}
              data-testid="join_again_btn"
            >
              Reconectar
            </Button>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
};

export default PostLeave;
